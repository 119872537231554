<template>
    <div class="content">
        <div class="more-info">
            <div class="col col-left">
                <div class="group">
                    <div class="group-header">
                        <h4>Медійна діяльність</h4>
                        <p class="group-header__p">
                            <img class="group-header__img" src="@/assets/images/info-yellow.svg" alt="svg">
                            Цей блок відображає виключно таку Вашу медійну діяльність як: виступи на телебаченні, інтерв’ю або статті у друкованих та електронних ЗМІ. Для соціальних мереж переходьте у наступний блок.
                        </p>
                    </div>
                    <div class="statistics">
                        <div class="chart">
                            <Doughnut :options="chartOptions" :chart-data="chartData" v-if="channelsStats.length"/>
                        </div>
                        <div class="statistics-info">
                            <div class="statistics-title">
                                <span>Телеканали</span>
                                <span>Ефіри</span>
                            </div>
                            <ul class="channels-list">
                                <li v-for="item in channelsStats">
                                    <div>
                                        <span class="color" :style="{background: channelsColor[item.id]}"></span>
                                        «{{item.title}}»
                                    </div>
                                    <span>{{item.count}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="group">
                    <Accordion @open="setMedia">
                        <div slot="header">Телеканали:</div>
                        <p v-if="!channelsHistory.length && !isMediaLoaded">Завантаження...</p>
                        <div class="channel" v-for="item in channelsHistory">
                             <div class="channel-top">
                                <div class="channel-title">Телеканал «{{item.title}}»</div>
                               <!-- <span class="lives">{{item.items.length}} ефіри</span> -->
                            </div>
                            <div v-for="item1 in item.items">
                                <div class="live-date">{{ item1.activity_date | moment("DD MMMM") }}</div>
                                <span class="channel__description" v-html="urlify(item1.description)"></span>
                            </div>
                        </div>
                    </Accordion>
                </div>

                <div class="group">
                    <div class="group-header">
                        <h4>Депутатська діяльність</h4>
                    </div>
                    <div class="presence group-block">
                        <div class="group-block_title">
                            <h4>Присутність на  сесіях:</h4>
                            <span class="percent">{{attendance.attendancePercent}}%</span>
                        </div>
                        <progress id="file" max="100" :value="attendance.attendancePercent"> 100% </progress>
                    </div>

                </div>

                <div class="group">
                    <Accordion @open="setVotes">
                        <div slot="header">Голосування:</div>
                        <p v-if="!votes.length && !isVotesLoaded">Завантаження...</p>
                        <div class="voting" v-for="vote in votes">
                            <div class="votin-info">
                                <span class="voting-date">{{ vote.activity_date | moment("DD MMMM") }}</span>
                                <span
                                    class="voting-result"
                                    :class="{
                                        yes: +vote.vote_id === 0,
                                        against: +vote.vote_id === 1,
                                        abstain: +vote.vote_id === 3
                                    }"
                                >{{vote.vote}}</span>
                            </div>
                            <p class="voiting-text">{{vote.title}}</p>
                        </div>
                    </Accordion>
                </div>

                <div class="group">
                    <Accordion @open="setInitiatives">
                        <div slot="header">Ініціативи в раді:</div>
                        <p v-if="!initiatives.length && !isInitiativesLoaded">Завантаження...</p>
                        <div v-for="initiative in initiatives" class="channel">
                            <div class="channel-top">{{initiative.title}}</div>
                            <div class="live-date">{{ initiative.activity_date | moment("DD MMMM") }}</div>
                        </div>
                    </Accordion>
                </div>
            </div>
            <div class="col">
                <div class="group">
                    <div class="group-header">
                        <h4>Профіль у соціальних мережах</h4>
                        <p class="group-header__p">
                            <img class="group-header__img" src="@/assets/images/info-yellow.svg" alt="svg">
                            Даний блок відображає всю Вашу діяльність у соціальних мережах. Система аналізує тільки ті сторінки, які закріплено у Вашому профілі. Ви можете змінити посилання натиснувши на знак редагування зліва.
                        </p>
                    </div>
                    <div class="group__body">
                        <div class="group__bodyUpper">
                            <div class="group__bodyItem">
                                <img src="@/assets/images/deputy-card/profile1.png" alt="png">
                                <div class="group__bodyItemText">
                                    <span>Пiдписники</span>
                                    <h5>1015</h5>
                                </div>
                            </div>
                            <div class="group__bodyItem">
                                <img src="@/assets/images/deputy-card/profile2.png" alt="png">
                                <div class="group__bodyItemText">
                                    <span>Пiдписники2</span>
                                    <h5>1015</h5>
                                </div>
                            </div>
                            <div class="group__bodyItem">
                                <img src="@/assets/images/deputy-card/profile3.png" alt="png">
                                <div class="group__bodyItemText">
                                    <span>Пiдписники3</span>
                                    <h5>1015</h5>
                                </div>
                            </div>
                            <div class="group__bodyItem">
                                <img src="@/assets/images/deputy-card/profile4.png" alt="png">
                                <div class="group__bodyItemText">
                                    <span>Пiдписники3</span>
                                    <h5>1015</h5>
                                </div>
                            </div>
                            <div class="group__bodyItem">
                                <img src="@/assets/images/deputy-card/profile5.png" alt="png">
                                <div class="group__bodyItemText">
                                    <span>Пiдписники3</span>
                                    <h5>1015</h5>
                                </div>
                            </div>
                            <div class="group__bodyItem">
                                <img src="@/assets/images/deputy-card/profile6.png" alt="png">
                                <div class="group__bodyItemText">
                                    <span>Пiдписники3</span>
                                    <h5>1015</h5>
                                </div>
                            </div>
                        </div>
                        <div class="group__bodyDown">
                            <div class="group__bodyDownItem">
                                <strong>Доповнені репости</strong><div class="dots"></div><span class="number">240</span>
                            </div>
                             <div class="group__bodyDownItem">
                                <strong>Охоплення</strong><div class="dots"></div><span class="number">1040</span>
                            </div>
                             <div class="group__bodyDownItem">
                                <strong>Репости</strong><div class="dots"></div><span class="number">4340</span>
                            </div>
                             <div class="group__bodyDownItem">
                                <strong>Доповнені репости 2</strong><div class="dots"></div><span class="number">10</span>
                            </div>
                        </div>
                    </div>
                    <div class="group__body group__body--links">
                        <h4>Посилання</h4>
                        <div class="group__bodyLinks">
                            <input id="cb" type="text" hidden>

                            <div class="group__bodySomeLink">
                                <p><img src="@/assets/images/facebook.svg" alt="svg">Facebook</p>
                                <p><a href="#">https://www.facebook.com/504729439890288/Facebook</a><img style="cursor: pointer" @click="copyToClip('https://www.facebook.com/504729439890288/Facebook');" src="@/assets/images/link.png" alt="png"></p>
                            </div>
                            <div class="group__bodySomeLink">
                                <p><img src="@/assets/images/twitter.svg" alt="svg">Twitter</p>
                                <p><a href="#">https://www.facebook.com/504729439890288/twitter</a><img style="cursor: pointer" @click="copyToClip('https://www.facebook.com/504729439890288/twitter');" src="@/assets/images/link.png" alt="png"></p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="group">
                    <div class="group-header">
                        <h4>Партійна діяльність</h4>
                    </div>
                    <Accordion @open="setActions">
                        <div slot="header">Акції:</div>
                        <p v-if="!actions.length && !isActionsLoaded">Завантаження...</p>
                        <div class="promotion" v-for="action in actions">
                            <div class="promotion-info">
                                <span class="promotion-date">{{ action.activity_date | moment("DD MMMM") }}</span>
                            </div>
                            <h5 class="promotion-title">{{action.title}}</h5>
                            <div class="promotion__description" v-html="urlify(action.description)"></div>
                        </div>
                    </Accordion>
                </div>
            </div>
        </div>
        <div class="group" style="max-width: 100%">
            <div class="group-header">
                <h4>Навчання</h4>
            </div>
            <Accordion @open="setTrainings">
                <div slot="header">Онлайн тренінги:</div>
                <p v-if="!trainings.length && !isTrainingsLoaded">Завантаження...</p>
                <div class="study" v-for="training in trainings">
                    <h5 class="study-title">{{training.title}}</h5>
                    <!-- fix  -->
                    <pre class="study-author" v-html="training.description"></pre>
                    <!-- <p class="study-author">{{training.description}}</p> -->
                </div>
            </Accordion>
        </div>
    </div>
</template>

<script>
import Accordion from "../components/ui/Accordion";
import Doughnut from "@/components/Doughnut";
import ActivityService from "@/services/ActivityService";

export default {
    name: '',
    components: {
        Accordion,
        Doughnut
    },
    props: ['deputy_id'],
    data() {
        return {
            channelsColor: [
                "#1a2744",
                "#F04F23",
                "#CA9A61",
                "#EADE1A",
                "#CA61C5",
                "#61C4CA",
                "#6164CA",
                "#61CA84",
                "#1D5792",
                "#1abc9c",
                "#1dd1a1",
                "#9b59b6",
                "#341f97",
                "#f39c12",
                "#c0392b",
                "#ff9ff3",
                "#607D8B",
                "#33691E"
            ],
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                showLines: false,
                animation: {
                    animateRotate: false
                },
                tooltips: {
                    enabled: false
                },
                hover: {mode: null},
                legend: {
                    display: false
                },
            },
            channelsHistory: [],
            attendance: [],
            votes: [],
            actions: [],
            initiatives: [],
            trainings: [],
            channelsStats: [],

            isMediaLoaded: false,
            isVotesLoaded: false,
            isInitiativesLoaded: false,
            isActionsLoaded: false,
            isTrainingsLoaded: false,
        }
    },
    async created() {
        let data = await ActivityService.mediaStats(this.deputy_id);
        data = data.sort((a, b) => {
            if(a.count > b.count) {
                return -1;
            } else if(a.count < b.count) {
                return 1;
            }
            return 0;
        });

        await this.setAttendance();
        this.channelsStats = data;
    },
    methods: {
        copyToClip(txt) {
            let cb = document.getElementById("cb");
            cb.value = txt;
            cb.style.display='block';
            cb.select();
            let res = document.execCommand('copy');
            cb.style.display='none';
            if (res) {
                alert('Copied')
            }
        },
        async setMedia() {
            let data = await ActivityService.media(this.deputy_id);
            this.isMediaLoaded = true
            data = data.sort((a, b) => {
                if(a.items.length > b.items.length) {
                    return -1;
                } else if(a.items.length < b.items.length) {
                    return 1;
                }
                return 0;
            });

            this.channelsHistory = data;
        },
        async setVotes() {
            this.votes = await ActivityService.votes(this.deputy_id);
            this.isVotesLoaded = true
        },
        async setInitiatives() {
            this.initiatives = await ActivityService.get(this.deputy_id, 4);
            this.isInitiativesLoaded = true
        },
        async setAttendance() {
            this.attendance = await ActivityService.attendance(this.deputy_id);
        },
        async setActions() {
            this.actions = await ActivityService.get(this.deputy_id, 5);
            this.isActionsLoaded = true
        },
        async setTrainings() {
            this.trainings = await ActivityService.get(this.deputy_id, 6);
            this.isTrainingsLoaded = true
        },
        clearPreloader(e) {
            console.log(e);
        },
        urlify(text) {
            let urlRegex = /(https?:\/\/[^\s]+)/g;
            text = text.replace(/[\u00A0-\u9999<>\&]/g, function(i) {
                return '&#'+i.charCodeAt(0)+';';
            });

            return text.replace(urlRegex, function(url) {
                return '<a href="' + url + '" target="_blank">' + url + '</a>';
            })
        },
    },
    computed: {
        chartData() {
            let dataset = {
                borderWidth: 0,
                backgroundColor: [],
                data: []
            };

            this.channelsStats.forEach(item => {
                dataset.backgroundColor.push(this.channelsColor[item.id]);
                dataset.data.push(item.count);
            });

            return {datasets: [dataset]};
        }
    }
}
</script>

<style lang="less">
    .chart canvas{
        width: 144px!important;
        height: 144px!important;
        @media screen and (max-width: 1320px) {
            margin: 0 auto 10px auto !important;
            object-fit: cover !important;
        }
    }
</style>

<style lang="less"  scoped>
    .content {
        padding: 80px 40px;
        width: 70%;
        height: 100vh;
        overflow-y: auto;
        background: #F5F5F5;
        @media screen and (max-width: 1440px) {
            padding: 80px 30px 30px 30px;
        }
        @media screen and (max-width: 480px) {
            padding: 20px;
        }
    }

   .group {
        max-width: 480px;
        margin-bottom: 40px;
        &-header {
            font-size: 25px;
            line-height: 35px;
            color: #1A2744;
            position: relative;
            padding-bottom: 20px;
            margin: 0 20px 20px 0;

            &:before {
                position: absolute;
                content: '';
                left: 0;
                bottom: -1px;
                width: 100%;
                height: 1px;
                background: #fff;
                opacity: 0.1;
            }
            &:after {
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background: #000;
                opacity: 0.15;
            }
        }
        @media screen and (max-width: 1024px) {
            max-width: 100%;
            width: 100%;
        }

        &__body {
            margin-bottom: 10px;
            background-color: #fff;
            border-radius: 8px;

            &--links {
                padding: 20px 25px;
            }
        }

        &__bodyUpper {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            padding: 20px 20px 10px;
        }

        &__bodyItem {
            flex-basis: calc(50% - 10px);
            display: flex;
            flex-wrap: wrap;
            border: solid 1px #f5f5f5;
            padding: 10px;
            margin: 5px;

            img {
                height: 50px;
                margin-right: 20px;
            }
        }

        &__bodyItemText {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 55%;

            h5 {
                font-size: 30px;
                font-weight: bold;
                color: #1a2744;
            }

            span {
                font-size: 13px;
                font-weight: bolder;
                color: #9da3ae;
            }
        }

        &__bodyDown {
            padding: 20px 0px 30px;
        }

        &__bodyDownItem {
            display: flex;
            width: 100%;
            padding: 0 25px;
            padding-top: 20px;

            strong {
                width: 100%;
            }

            .dots {
                width: 100%;
                border-bottom: 1px dashed #acabab;
                position: relative;
                top: -3px;
            }

            span {
                margin-left: auto;
                font-weight: bold;
                margin-top: auto;
            }
        }

        &__bodySomeLink {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding: 10px 0;

            p {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                color: #9da3ae;

                a {
                    word-break: break-word;
                }

                img {
                    height: 24px;
                    width: 24px;
                    margin-left: auto;

                    &:first-child {
                        margin-right: 10px;
                        margin-left: 0;
                    }
                }
            }
        }
   }.accordion .body {
       height: inherit;
   }

    .group-block {
        max-width: 480px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 20px;
        @media screen and (max-width: 1024px) {
            max-width: 100%;
            width: 100%;
        }
    }
    .group-block_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        h4 {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.14px;
            color: #1A2744;
        }
        @media screen and (max-width: 1360px) {
            font-size: 18px;
        }
    }
    .percent {
        font-weight: bolder;
        font-size: 27px;
        line-height: 32px;
        color: #A7DA2A;
    }

    ::-webkit-progress-bar {
        background: #fff;
    }
    ::-webkit-progress-value {
        background: #ffc900;
    }
    ::-moz-progress-bar {
        background: #ffc900;
    }
    progress {
        display: block;
        color: red;
        background: yellow;
        border-radius: 8px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        height: 4px;
        line-height: 21px;
        font-size: 15px;
        font-family: sans-serif;
        text-align: center;
    }



   .statistics {
        width: 100%;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 20px;
        display: flex;
        @media screen and (max-width: 1320px)  {
            display: block;
        }
   }
   .chart {
       margin-top: 20px;
       margin-right: 20px;
       width: 144px;
       height: 144px;
       @media screen and (max-width: 1320px) {
            margin: 0 auto 10px;
       }

   }
   .statistics-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        span {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #9DA3AE;
        }
   }
    .statistics-info {
        max-width: 290px;
        width: 65%;
        @media screen and (max-width: 1320px) {
            max-width: 100%;
            width: 100%;
        }
    }
   .channels-list {
        max-width: 290px;
        width: 100%;
        @media screen and (max-width: 1320px) {
            max-width: 100%;
            width: 100%;
        }
        li {
            list-style: none;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.112px;
            color: #1A2744;
            margin-bottom: 3px;
            display: flex;
            justify-content: space-between;
            span.color{
                display: inline-block;
                width: 20px;
                height: 12px;
                margin-right: 12px;
                border-radius: 12px;
            }
            span {
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
            }
        }
        @media screen and (max-width: 480px)  {
            display: block;
        }
   }

   .channel {
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 13px;
        margin-bottom: 10px;
        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
        }
        &-title {
            font-size: 16px;
            line-height: 24px;
            color: #1A2744;
            margin-bottom: 10px;
        }
        &-top {
            display: flex;
            justify-content: space-between;
        }

        &-link {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.098px;
            text-decoration: underline;
            color: #5082F3;
        }

        &__description {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
        }
   }
    .lives {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #9DA3AE;
    }
   .live-date {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #9DA3AE;
        margin-bottom: 5px;
   }

    .voting {
        padding-bottom: 23px;
        border-bottom: 1px solid #cdd4e0;
        margin-bottom: 20px;
        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }
      .voting:last-child {
      margin-bottom: 0;}

    .votin-info {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .voiting-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #1A2744;
    }
    .voting-date {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #9DA3AE;
    }
    .voting-result {
        font-size: 20px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: 24px;
        text-align: right;
    }
    .voting-result.yes {
         color: #a7da2a;
    }
    .voting-result.abstain {
        color: #ff9623;
    }
    .voting-result.against {
        color: #f40035;
    }

    .more-info {
        width: 100%;
        display: flex;
        @media screen and (max-width: 1024px) {
            display: block;
        }
    }

    .col {
        max-width: 480px;
        width: 50%;
        @media screen and (max-width: 1024px) {
            max-width: 100%;
            width: 100%;
        }
    }
    .col-left {
        margin-right: 60px;
        @media screen and (max-width: 1440px) {
            margin-right: 30px
        }
        @media screen and (max-width: 1024px) {
            margin-right: 0;
        }
    }

    .promotion {
        border-bottom: 1px solid #f5f5f5;;
        padding-bottom: 13px;
        margin-bottom: 10px;
        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }
        &-info {
        margin-bottom: 3px;
        }
        &-date {
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #9DA3AE;
        }
        &-title {
           font-size: 16px;
           line-height: 24px;
           color: #1A2744;
           margin-bottom: 10px;
        }
        &-link {
         font-weight: normal;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: -0.098px;
         text-decoration: underline;
         color: #5082F3;
        }

        &__description {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
        }
    }

    .study {
        padding-bottom: 20px;
        border-bottom: 1px solid #F5F5F5;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
        &-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #1A2744;
        }
        &-author {
            font-size: 14px;
            line-height: 24px;
            color: #9DA3AE;
            letter-spacing: normal;
            white-space: pre-wrap;

            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
        }
    }


    .cards {
        background-color: #f5f5f5;
        height: 100vh;

        .common-block {
            background: #1a2744  ;
              .title {
                    display: none  !important;
                }
        }

        &-wrapper {
            display: flex;
            height: 100vh;
        }


        &-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        &-item {
            max-width: 32%;
            height: 256px;
            background: #FFFFFF;
            padding: 40px 40px 24px 40px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            position: relative;

            &:hover {
                transform: scale(1.01);
                box-shadow: 0 24px 24px -14px rgb(249 198 35 / 35%);
            }

            &_avatar {
                max-width: 70px;
                height: 70px;
                margin-right: 40px;
                width: 30%;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            &_inner {
                display: flex;
            }

            &_name {
                font-weight: bold;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: -0.14px;
                color: #1A2744;
            }

            &_location {
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #1A2744;
                margin-bottom: 9px;
            }

            .positions {
                border-bottom: 1px solid #F5F5F5;
                padding-bottom: 20px;
                margin-bottom: 20px;
            }

            .position {
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.112px;
                color: #1A2744;
            }

            &_phone {
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                color: #1A2744;
            }
        }
    }




    @media screen and (max-width: 1920px) {
        .content {
            max-width: 1280px;
            width: 70%;
        }

        .cards-inner {
            max-width: 640px;
            width: 30%;
        }

        .cards-items {
            justify-content: space-between;
        }

        .cards-item {
            max-width: 592px;
            width: 49%;
            margin-right: 0;
        }

        .group-header {

            &__img {
                position: absolute;
                left: 0px;
                top: 0px;
                height: 20px;
                width: 20px;
            }

            &__p {
                font-size: 13px;
                line-height: initial;
                padding-left: 30px;
                position: relative;
                margin-top: 10px;
            }
        }
    }

    @media screen and (max-width: 1600px) {
        .content {
            max-width: 1280px;
            width: 70%;
            padding: 80px 40px 40px;
        }

        .cards-inner {
            padding: 40px 40px 40px 50px;
        }
    }

    @media screen and (max-width: 1440px) {
        .cards-title {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 20px;
        }

        .filter-title {
            font-size: 20px;
            line-height: 24px;
        }

        .filter-reset {
            font-size: 14px;
            line-height: 18px;
        }
        .group-header {
            font-size: 28px;
            line-height: 34px;
        }
    }
    @media screen and (max-width: 1360px)  {
        .group-header {
            font-size: 20px;
            line-height: 30px;
        }
        .group {
            margin-bottom: 30px;
        }
    }

    @media screen and (max-width: 1200px) {

    }

    @media screen and (max-height: 700px) {

    }

</style>

